<template>
  <div>
    <user-account-billing-mobile-menu v-if="$mq === $enums.mqBreakpoints.MOBILE"/>
    <user-account-billing-tabs v-else/>
  </div>
</template>

<script>
import UserAccountBillingTabs from '@/views/modules/user/user-billing/UserAccountBillingTabs.vue';
import UserAccountBillingMobileMenu from '@/views/modules/user/user-billing/UserAccountBillingMobileMenu.vue';

/**
 * User account configurations and details
 *
 * @module views/modules/user-account/UserAccountBilling
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 */
export default {
  name: 'UserAccountBilling',
  components: {
    UserAccountBillingTabs,
    UserAccountBillingMobileMenu,
  },
};
</script>
