<template>
  <dynamic-tabs
    tabs-id="user-billing-tabs"
    :tabs="tabs"/>
</template>

<script>
import DynamicTabs from '@/views/modules/_components/DynamicTabs.vue';
import UserAccountBillingHistory from '@/views/modules/user/user-billing/user-billing-history/UserAccountBillingHistory.vue';
import UserAccountBillingInformation from '@/views/modules/user/user-billing/UserAccountBillingPaymentMethods.vue';

/**
 * User account tabs
 *
 * @module views/modules/user-account/UserAccountBillingTabs
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {Object[]} [tabs=[...]] - tabs to show
 */
export default {
  name: 'UserAccountBillingTabs',
  components: {
    DynamicTabs,
  },
  data() {
    return {
      tabs: [
        {
          icon: 'icon-credit-card',
          label: this.$t('$UserSettings.$Billing.$PaymentMethods.TabTitle'),
          component: UserAccountBillingInformation,
          path: 'payment-methods',
        },
        {
          icon: 'icon-list',
          label: this.$t('$UserSettings.$Billing.$History.TabTitle'),
          component: UserAccountBillingHistory,
          path: 'history',
        },
      ],
    };
  },
};
</script>
