<template>
  <vs-tabs
    :id="tabsId"
    position="top"
    v-model="tabActive"
    class="tabs-shadow-none dynamic-tabs">

    <vs-tab
      v-for="(tab, index) in tabsFilteredByPermissions"
      :key="index"
      icon-pack="feather"
      :icon="tab.icon"
      :label="tab.label">
      <div>
        <vs-divider class="my-3"/>
        <keep-alive>
          <component
            v-bind:is="tab.component"
            v-bind="props"
            @go-to="(path) => goTo(path)"></component>
        </keep-alive>
      </div>
    </vs-tab>
  </vs-tabs>
</template>

<script>
import { mapGetters } from 'vuex';

/**
 * Dynamic tabs
 *
 * @module views/modules/_components/DynamicTabs.vue
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-props {Object[]} tabs - tabs to show
 * @vue-props {string} tabsId - tabs to show
 * @vue-props {number} relevantUrlPartOffset - offset for relevant url part from last part
 * @vue-props {Object} props - dynamic props to pass to rendered component
 * @vue-data {number} [tabActive=0] - active tab index
 * @vue-computed {Object[]} [tabsFilteredByPermissions=[...]] - tabs to show filtered by permissions
 * @vue-event {void} getTabIndexByPath - get tab index by path
 * @vue-event {void} goTo - go to tab by path
 */
export default {
  name: 'DynamicTabs',
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    tabsId: {
      type: String,
      required: true,
    },
    relevantUrlPartOffset: {
      type: Number,
      default: 0,
    },
    props: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      tabActive: 0,
    };
  },
  computed: {
    ...mapGetters({
      userHasPermissionTo: 'auth/userHasPermissionTo',
    }),
    tabsFilteredByPermissions() {
      return this.tabs.filter((tab) => !tab.permission || this.userHasPermissionTo(tab.permission));
    },
  },
  created() {
    const routeParts = this.$route.path.split('/');
    const relevantPart = routeParts[routeParts.length - (this.relevantUrlPartOffset + 1)] || '';
    this.tabActive = this.getTabIndexByPath(relevantPart);
  },
  methods: {
    getTabIndexByPath(path) {
      const index = this.tabs.findIndex((tab) => tab.path === path);
      return index < 0 ? 0 : index;
    },
    goTo(path) {
      this.tabActive = this.getTabIndexByPath(path);
    },
  },
};
</script>

<style lang="scss">
.dynamic-tabs {
  .vs-tabs--content {
    padding: 1rem 0 0 0;
  }

  @media (max-width: 768px) {
    .vs-tabs--content {
      padding: 0;
    }

    .sub-tabs .vs-tabs--content {
      padding: 1rem 0 0 0;
    }
  }
}
</style>
