<template>
  <div>
    <mobile-menu-group
      v-for="(group, index) in groups"
      :id="group.TabIdName"
      :key="index"
      :class="{'mt-base': index > 0}"
      :header-text="group.headerText"
      :items="group.items"/>
  </div>
</template>

<script>
import MobileMenuGroup from '@/views/modules/_components/MobileMenuGroup.vue';

/**
 * User account menu
 *
 * @module views/modules/user-account/UserAccountMenu
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {Object[]} [groups=[...]] - groups to show in the menu
 * @vue-event {void} logout - logout
 */
export default {
  name: 'UserAccountMenu',
  i18n: {
    messages: {
      en: {
        BillingOverview: 'See your billing overview',
        BillingPaymentMethods: 'Manage your payment methods',
        BillingHistory: 'See your billing history',
      },
    },
  },
  components: {
    MobileMenuGroup,
  },
  data() {
    return {
      groups: [
        {
          headerText: '',
          TabIdName: 'mobile-group-billing',
          items: [
            {
              title: this.$t('$UserSettings.$Billing.$PaymentMethods.TabTitle'),
              text: this.$t('BillingPaymentMethods'),
              icon: 'CreditCardIcon',
              path: '/billing-mobile/payment-methods',
            },
            {
              title: this.$t('$UserSettings.$Billing.$History.TabTitle'),
              text: this.$t('BillingHistory'),
              icon: 'ListIcon',
              path: '/billing-mobile/history',
            },
          ],
        },
      ],
    };
  },
};
</script>
